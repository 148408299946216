import React from "react";

import Helmet from "../components/Helmet";

function Layout({ children, title }) {
    return (
        <React.Fragment>
            <Helmet title={ title }/>
            <div className="container mb-5">
                <div className="content">
                    <div className="content-title">
                        <h1>{ title }</h1>
                    </div>
                    <div className="content-body">
                        { children }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(Layout);
